import React, { useMemo, useState } from 'react'
import { Article as ArticleType } from '../../types/Article'

export default function Article(props: { article: ArticleType }) {

  const [hidden, setHidden] = useState(true);
  const { article } = props;

  // Handle details
  const toggleDetails = () => {
    setHidden(state => !state);
  };

  const hasKeyPoints = useMemo(() => {
    if (!article.key_points) {
      return false
    }
    return article.key_points.length !== 0
  }, [article]);

  // If the article is not processed, return a loading screen
  if (!hasKeyPoints) {
    return (
      <div className='article card' style={{ backgroundColor: "#111" }}>
        Processing <a href={article.url} target='_blank'>{article.url}</a>...
      </div>
    )
  }

  return (
    <div className='article card' style={{ backgroundColor: "#111" }}>
      <h5>{article.headline}</h5>
      <div className="chip">{article.category}</div>
      <div className="chip">{article.worth_reading ? 'worth reading' : 'not worth reading'}</div>
      <div className="chip">score: {article.rating}</div>
      {hasKeyPoints ? (
        <ul>
          {article.key_points.map((key_point, idx) => (
            <li key={idx}>- {key_point}</li>
          ))}
        </ul>
      ) : ''}
      <h6>Bias:</h6>
      <p>{article.bias}</p>
      {/* <p>Take away: {article.take_away}</p> */}
      <p>Source: <a href={article.url} target="_blank">{article.url}</a></p>
      <div className={hidden ? 'hide' : ''}>
        <h5>Summary</h5>
        {article.summary}
        <h5>Take away</h5>
        {article.take_away}
      </div>
      <p className='blue-text' style={{ cursor: 'pointer' }} onClick={toggleDetails}>
        {hidden ? 'See more' : 'Hide'} details
      </p>
      <hr />
    </div>
  )
}
