import React from 'react'
import { useUser } from '../../context/UserContext';
import Loading from '../Loading/Loading';
import './Profile.css';
import { Navigate } from 'react-router';


export default function Profile() {

    const { loading, authUser, profile } = useUser();

    if (loading) return (<Loading />)

    if (!authUser) return (<Navigate to="/home" />)

    return (
        <div className='profile'>

            {/* Side bar */}
            <div className='sidebar shade1'>
                <div className="profile-image">
                    <img src={authUser.photoURL || 'user_avatar.png'} />
                </div>
                <h4>{authUser.displayName}</h4>
            </div>

            {/* Main content */}
            <div className='main-content shade2'>
                <h5>Profile</h5>
                <pre>{JSON.stringify(profile, null, 2)}</pre>
                <hr />
                <h5>Auth user</h5>
                <pre>{JSON.stringify(authUser, null, 2)}</pre>
            </div>

        </div>
    )
}
