import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDW4gfETJKB-aXvP_p_UPQbwMnUJ9buDss",
    authDomain: "news-1a30d.firebaseapp.com",
    projectId: "news-1a30d",
    storageBucket: "news-1a30d.appspot.com",
    messagingSenderId: "340207768477",
    appId: "1:340207768477:web:b5524df143d6b6a0dc5574",
    measurementId: "G-VCGWCWL26W"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app);