import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../config/firebase';
import { collection, addDoc, onSnapshot, query, orderBy, limit, Timestamp } from '@firebase/firestore';
import { Article as ArticleType } from '../../types/Article';
import Article from './Article';

const NEWS_COLLECTION = 'articles';

export default function News() {
  const [news, setNews] = useState<ArticleType[]>([]);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmitSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const article = {
      url: searchText,
      createdAt: Timestamp.now(),
    };
    const docRef = await addDoc(collection(db, NEWS_COLLECTION), article);
    console.log('Document written with ID: ', docRef.id);

    setSearchText('');
  };

  // Load news on page load
  useEffect(() => {
    const collectionRef = collection(db, NEWS_COLLECTION);
    const q = query(collectionRef, orderBy('createdAt', 'desc'), limit(20));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => {
        const article = doc.data() as ArticleType;
        article.id = doc.id;
        return article;
      });
      setNews(articles);
    });
    return () => unsubscribe();
  }, []);

  // Focus the input on component mount
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div>
      {/* Search bar */}
      <div className="container">
        <form onSubmit={handleSubmitSearch}>
          <input
            type="text"
            placeholder="Enter YouTube url..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ color: '#999' }}
            ref={inputRef}
          />
          <button type="submit">Process video</button>
        </form>
      </div>
      {/* Articles */}
      <div className="container" style={{ color: '' }}>
        {news.map((article) => {
          return <Article key={article.id} article={article} />;
        })}
      </div>
    </div>
  );
}
