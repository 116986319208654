import React from 'react'

import './Nav.css';
import { useUser } from '../../context/UserContext';
import { Link } from 'react-router-dom';
/*
This navbar will always be shown on top of the page.
It will show different options depending on the user.

User is not authenticated:
- Show login or signup button

User is authenticated:
- Show profile button
- Show logout button
*/
export default function Nav() {

    const { authUser } = useUser();

    return (
        <nav className='nav nav-wrapper shade1'>
        <Link to="/home" className="brand-logo title">news</Link>
        <ul className="right">
            { authUser ? (<>
                <li><Link to="profile">Profile</Link></li>
                <li><Link to="logout">Logout</Link></li>
            </>) : (<>
                <li><Link to="signin">Sign in</Link></li>
            </>)}
        </ul>
    </nav>
)
}
