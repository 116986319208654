import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import { useUser } from './context/UserContext';

import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Profile from './components/Profile/Profile';
import SignIn from './components/SignIn/SignIn';
import Chat from './components/Chat/Chat';
import News from './components/News.tsx/News';

type ProtectedProps = {
  condition: boolean | undefined;
  children: JSX.Element;
}

function Protected({ condition, children }: ProtectedProps) {
  return condition ? children : <Navigate to="/" />;
}

export default function AppRoutes() {

  const { profile, loading } = useUser();

  return (
    <>
      <Routes>
        {/* General */}
        <Route path="/" element={<Navigate to="/news" />} />
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/news" element={<News />} />

        {/* Auth */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        {/* Components */}
        <Route path="/profile" element={<Profile />} />

        {/* 404 */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  )
}
