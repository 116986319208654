import React, { useEffect, useState } from 'react'
import { db } from '../../config/firebase';
import { collection, addDoc, onSnapshot, query, where, orderBy, limit, Timestamp } from '@firebase/firestore';
import './Chat.css';
import { Session } from '../../types/Session';
import { Message } from '../../types/Message';
import ReactMarkdown from 'react-markdown';

const CHAT_COLLECTION = 'chat';
const MESSAGES_COLLECTION = 'messages';

export default function Chat() {

  const [session, setSession] = useState<Session>();
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionId, setSessionId] = useState<String>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatBox, setChatBox] = useState<string>('');

  // Get the latest 10 sessions
  useEffect(() => {
    const collectionRef = collection(db, CHAT_COLLECTION);
    const q = query(collectionRef, orderBy("createdAt", "desc"), limit(50));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const sessions = snapshot.docs.map(doc => {
        const session = doc.data() as Session
        session.id = doc.id;
        return session;
      });
      setSessions(sessions);

    })
    return () => {
      unsubscribe();
    }
  }, []);

  // Create session on page load
  useEffect(() => {
    // Create a session document in the chat collection
    const session = {
      createdAt: Timestamp.now(),
    };
    addDoc(collection(db, CHAT_COLLECTION), session)
      .then((docRef) => {
        console.log(`Session document written with ID: ${docRef.id}`);
        setSessionId(docRef.id);
      })
      .catch((error) => {
        console.error(`Error adding session document: ${error}`);
      });
  }, []);

  // Get messages from chat/sessionId/messages
  useEffect(() => {
    console.log(`Getting messages for session ${sessionId}`)
    if (!sessionId) return;
    const collectionRef = collection(db, `${CHAT_COLLECTION}/${sessionId}/${MESSAGES_COLLECTION}`);
    const q = query(collectionRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map(doc => doc.data() as Message));
    })
    return () => {
      unsubscribe();
    }
  }, [sessionId]);

  // Update the selected session
  useEffect(() => {
    for (const session of sessions) {
      if (session.id === sessionId) {
        setSession(session);
        break;
      }
    }
  }, [sessions, sessionId])

  // Handle submit message
  const handleSubmitMessage = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const message = {
      id: Math.random().toString(36).substr(2, 9),
      text: chatBox,
      timestamp: Timestamp.now(),
      role: 'user',
      sessionId: sessionId,
    };
    // Add message to chat/sessionId/messages collection
    const docRef = await addDoc(collection(db, `${CHAT_COLLECTION}/${sessionId}/${MESSAGES_COLLECTION}`), message);
    console.log('Document written with ID: ', docRef.id);

    setChatBox('');
  }

  return (
    <div>
      <div className='containerX'>
        <div className='row'>
          <div className='col s3'>
            <p>Sessions</p>
            <ul>
              {sessions.map(session => {
                return <li key={session.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSessionId(session.id);
                  }}
                  className={`truncate session ${session.id === sessionId ? 'active' : ''}`}
                  >
                  {session.title || new Date(session.createdAt.toMillis()).toLocaleString()}
                </li>
              })}
            </ul>
          </div>
          <div className='col s9'>
            {session?.title && <h2>{session.title}</h2>}
            {messages.map(message => {
              return <div key={message.id} className={`message role-${message.role}`}>
                <ReactMarkdown>
                  {message.text}
                </ReactMarkdown>
              </div>
            })}
            {/* Input chat box */}
            <div className='containerX'>
              <form onSubmit={handleSubmitMessage}>
                <input type='text' value={chatBox} onChange={(e) => setChatBox(e.target.value)} style={{ color: '#999' }} />
                <button>Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
